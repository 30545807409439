/* TODO: Make this SASS compliant */
/* line 16, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollbar {
	-ms-touch-action: none;
	touch-action: none;
}

/* line 20, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollbar.mCS_no_scrollbar {
	-ms-touch-action: auto;
	touch-action: auto;
}

/* line 24, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollBox {
	direction: ltr;
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .mCustomScrollBox {
	direction: rtl;
	unicode-bidi: bidi-override;
}

/* line 32, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container {
	overflow: hidden;
	width: auto;
	height: auto;
}

/* line 37, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_inside > .mCSB_container {
	margin-right: 30px;
}

/* line 40, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
}

/* line 44, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools {
	position: absolute;
	width: 16px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
}

/* line 53, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_outside + .mCSB_scrollTools {
	right: -26px;
}

/* line 57, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_draggerContainer {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: auto;
}

/* line 65, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools a + .mCSB_draggerContainer {
	margin: 20px 0;
}

/* line 68, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_draggerRail {
	border-radius: 16px;
	width: 2px;
	height: 100%;
	margin: 0 auto;
}

/* line 74, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_dragger {
	cursor: pointer;
	width: 100%;
	height: 30px;
	z-index: 1;
}

/* line 80, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	position: relative;
	width: 5px;
	height: 100%;
	margin: 0 auto;
	text-align: center;
}

/* line 87, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	width: 12px;
}

/* line 91, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	width: 8px;
}

/* line 95, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
	display: block;
	position: absolute;
	height: 20px;
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

/* line 105, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonDown {
	bottom: 0;
}

/* line 108, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_horizontal.mCSB_inside > .mCSB_container {
	margin-right: 0;
	margin-bottom: 30px;
}

/* line 112, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_horizontal.mCSB_outside > .mCSB_container {
	min-height: 100%;
}

/* line 115, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}

/* line 118, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	width: auto;
	height: 16px;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
}

/* line 126, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
	bottom: -26px;
}

/* line 130, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
	margin: 0 20px;
}

/* line 133, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
	width: 100%;
	height: 2px;
	margin: 7px 0;
}

/* line 138, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
	width: 30px;
	height: 100%;
	left: 0;
}

/* line 143, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
	width: 100%;
	height: 4px;
	margin: 6px auto;
}

/* line 148, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
	height: 12px;
	margin: 2px auto;
}

/* line 153, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
	height: 8px;
	margin: 4px 0;
}

/* line 158, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	display: block;
	position: absolute;
	width: 20px;
	height: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

/* line 168, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
	left: 0;
}

/* line 171, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
	right: 0;
}

/* line 174, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper {
	position: absolute;
	height: auto;
	width: auto;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: 30px;
}

/* line 186, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper > .mCSB_container {
	padding-right: 30px;
	padding-bottom: 30px;
}

/* line 190, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 20px;
}

/* line 193, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 20px;
}

/* line 196, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
	bottom: 0;
}

/* line 199, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 0;
}

/* line 203, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
	padding-right: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* line 209, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
	padding-bottom: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* line 215, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
	margin-left: 0;
}

/* line 219, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
	margin-bottom: 0;
}

/* line 222, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

/* line 233, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
	-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}

/* line 242, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools {
	opacity: .75;
	filter: "alpha(opacity=75)";
	-ms-filter: "alpha(opacity=75)";
}

/* line 247, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
	opacity: 0;
	filter: "alpha(opacity=0)";
	-ms-filter: "alpha(opacity=0)";
}

/* line 253, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
	opacity: 1;
	filter: "alpha(opacity=100)";
	-ms-filter: "alpha(opacity=100)";
}

/* line 263, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: #D3D3D3;
	background-color: lightgray;
	filter: "alpha(opacity=100)";
	-ms-filter: "alpha(opacity=100)";
}

/* line 270, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	background-color: #535353;
	background-color: rgba(83, 83, 83, 0.6);
	filter: "alpha(opacity=60)";
	-ms-filter: "alpha(opacity=60)";
}

/* line 277, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: #535353;
	background-color: rgba(83, 83, 83, 0.6);
	filter: "alpha(opacity=60)";
	-ms-filter: "alpha(opacity=60)";
}

/* line 285, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	background-image: url(mCSB_buttons.png);
	background-repeat: no-repeat;
	opacity: .4;
	filter: "alpha(opacity=40)";
	-ms-filter: "alpha(opacity=40)";
}

/* line 295, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonUp {
	background-position: 0 0;
}

/* line 298, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonDown {
	background-position: 0 -20px;
}

/* line 301, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonLeft {
	background-position: 0 -40px;
}

/* line 304, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonRight {
	background-position: 0 -56px;
}

/* line 307, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
	opacity: .75;
	filter: "alpha(opacity=75)";
	-ms-filter: "alpha(opacity=75)";
}

/* line 315, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
	opacity: .9;
	filter: "alpha(opacity=90)";
	-ms-filter: "alpha(opacity=90)";
}

/* line 325, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCSB_inside > .mCSB_container {
	margin-right: 0;
	margin-left: 30px;
}

/* line 329, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-left: 0;
}

/* line 332, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCSB_inside > .mCSB_scrollTools,
.is-rtl .mCSB_outside + .mCSB_scrollTools {
	right: auto;
	left: 0;
}

/* line 337, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCSB_outside + .mCSB_scrollTools {
	left: -26px;
}

/* line 340, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
	right: 0;
	left: 20px;
}

/* line 344, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
	left: 0;
}

/* line 347, scss/80-themes/AffinityDefault/60-components/custom-scrollbar.scss */
.is-rtl .mCSB_inside > .mCSB_container_wrapper {
	margin-right: 0;
	margin-left: 30px;
}

/*# sourceMappingURL=../../../../true */